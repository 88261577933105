import * as React from "react"
// Components
import SEO from "../components/seo"
import LocalizedLink from "../components/links/localizedLink"
// Translations
import useLocalizationKeys from "../components/localization-utils/useLocalizationKeys"
import useTranslation from "../components/localization-utils/useTranslation"

const NotFoundPage: React.FC = (): JSX.Element => {
  const { notFoundPage, SEO: s } = useLocalizationKeys()
  return (
    <>
      <SEO title={useTranslation(s.notFound.title)} description={useTranslation(s.notFound.description)}/>
      <div className="page-header">
        <h1>{useTranslation(notFoundPage.title)}</h1>
        <p>{useTranslation(notFoundPage.firstLine)}</p>
        <p>
          {useTranslation(notFoundPage.secondLine)}
          <LocalizedLink to="/">
            {useTranslation(notFoundPage.modeDevCentral)}
          </LocalizedLink>
        </p>
      </div>
    </>
  )
}

export default NotFoundPage
